import { InjectionToken } from '@angular/core';

export const ADMIN_MODULE_CONFIG = new InjectionToken('ADMIN_MODULE_CONFIG');

export interface AdminModuleConfig {
  flavor: string;
  appName: string;
  logoUrl: string;
  logoWhiteUrl: string;
  backgroundImageUrl: string;
  mapDefaultLat: number;
  mapDefaultLng: number;
  mapDefaultZoom: number;
}
